import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
export class HttpCancelInterceptor implements HttpInterceptor {
  private cancelPendingRequests$ = new Subject<void>();

  // Intercepta todas las peticiones y las asocia al cancelPendingRequests$
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      takeUntil(this.cancelPendingRequests$) // Se cancelan cuando se emite un valor en cancelPendingRequests$
    );
  }

  // Método para cancelar todas las peticiones activas
  cancelAllRequests() {
    this.cancelPendingRequests$.next();
  }
}
